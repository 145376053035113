import React, { useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Flex, Box } from 'reflexbox';
import './resources.scss';
import { CommonContainer } from 'components/common-container';
import ContentBlock from 'components/content-block';
import { Button } from 'rebass';
import { Layout, Seo } from 'components';
import { appContext } from 'providers/appProvider';
// import { Grid } from 'reflexbox';
import { Row, Col } from 'react-flexbox-grid';

import visualAid from 'images/hcp/pages/resources/sprix-see-strength-cover.png';
import pi from 'images/hcp/pages/resources/isi-cover-thumb.png';
import direct from 'images/hcp/pages/resources/resources-sprix-direct.png';
import leaveBehind from 'images/hcp/pages/resources/sprix-see-strength-cover-white.png';
import oraPharma from 'images/hcp/pages/resources/for-pain-relief-choose-sprix.png';
import patBro from 'images/hcp/pages/resources/resources-pat-brochure.png';
import spanishBrochure from 'images/hcp/pages/resources/sprix-spanish-brochure-cover.png';

const pageTitle = 'HCP Resources';
const pageDescription = '';

const Resources = () => {
  const { appConfigs } = useContext(appContext);
  const siteIndications = appConfigs.siteOptions.INDICATIONS;
  const trackingPrescription = () => {
    window.ga(
      'send',
      'event',
      'navigation',
      'HCP Resources Direct Prescription form button',
      'HCP Resources Direct Prescription form ',
    );
  };

  const trackingHCPPI = () => {
    window.ga(
      'send',
      'event',
      'navigation',
      'HCP Resources Full Prescribing button',
      'HCP Resources HCP Full Prescribing',
    );
  };

  const trackingHCPCoreAid = () => {
    window.ga(
      'send',
      'event',
      'navigation',
      'HCP Resources Core Aid button',
      'HCP Resources Core Aid ',
    );
  };

  const trackingSpanishCoreAid = () => {
    window.ga(
      'send',
      'event',
      'navigation',
      'Patient Brochure – Spanish',
      'Patient Brochure – Spanish ',
    );
  };

  const trackingDentalPatient = () => {
    window.ga(
      'send',
      'event',
      'navigation',
      'Dental Patient Brochure',
      'Dental Patient Brochure',
    );
  };

  const trackingDentalProffesional = () => {
    window.ga(
      'send',
      'event',
      'navigation',
      'Dental Professional Brochure',
      'Dental Proffesional Brochure',
    );
  };

  const trackingHCPProffesional = () => {
    window.ga(
      'send',
      'event',
      'navigation',
      'HCP Professional Brochure',
      'HCP Professional Brochure',
    );
  };

  return (
    <Layout indication={siteIndications.hcp} className="hcp-resources">
      <Seo indication={siteIndications.hcp} pageTitle={pageTitle} />
      <CommonContainer>
        <div id="downloads">
          <ContentBlock>
            <h2 className="no-padding-bottom">
              <strong>Tools</strong>
            </h2>
          </ContentBlock>
        </div>

        <div className="blue">
          <div className="resource-container">
            <ContentBlock>
              <div className="box-container">
                <Flex>
                  <Box xs={3} className="first-block">
                    <img className="smaller" src={direct} />
                  </Box>
                  <Box xs={7} ml={4}>
                    <p>
                      SPRIX<sup>®</sup> DIRECT Prescription Form{' '}
                    </p>
                    <a
                      href={'/pdfs/sprix-direct-prescription-form-chsp.pdf'}
                      target={'_blank'}
                      onClick={trackingPrescription}
                    >
                      <Button backgroundColor={'#f7db4b'} color={'#19386e'}>
                        Download the SPRIX DIRECT Prescription Form
                      </Button>
                    </a>
                  </Box>
                </Flex>
              </div>
            </ContentBlock>
          </div>
        </div>
        <div className="empty-space-1rem"></div>
        <div className="lt-blue">
          <div className="resource-container">
            <ContentBlock>
              <div className="box-container">
                <Flex>
                  <Box xs={3} className="first-block">
                    <img src={leaveBehind} />
                  </Box>
                  <Box xs={7} ml={4}>
                    <p>
                      SPRIX<sup>®</sup> Healthcare Professional Brochure
                    </p>
                    <a
                      href={'/pdfs/Sprix_HCP_Leave_Behind.pdf'}
                      target={'_blank'}
                      onClick={trackingHCPProffesional}
                    >
                      <Button backgroundColor={'#f7db4b'} color={'#19386e'}>
                        Download the Brochure
                      </Button>
                    </a>
                  </Box>
                </Flex>
              </div>
            </ContentBlock>
          </div>
        </div>
        <div className="empty-space-1rem"></div>
        <div className="blue">
          <div className="resource-container">
            <ContentBlock>
              <div className="box-container">
                <Flex>
                  <Box xs={3} className="first-block">
                    <img src={oraPharma} />
                  </Box>
                  <Box xs={7} ml={4}>
                    <p>
                      SPRIX<sup>®</sup> Dental Professional Brochure
                    </p>
                    <a
                      href={
                        '/pdfs/Sprix_OraPharma_HCP_Detail_Aid_and_Leave Behind.pdf'
                      }
                      target={'_blank'}
                      onClick={trackingHCPPI}
                    >
                      <Button backgroundColor={'#f7db4b'} color={'#19386e'}>
                        Download Professional Dental Brochure
                      </Button>
                    </a>
                  </Box>
                </Flex>
              </div>
            </ContentBlock>
          </div>
        </div>
        <div className="empty-space-1rem"></div>
        <div className="lt-blue">
          <div className="resource-container">
            <ContentBlock>
              <div className="box-container">
                <Flex>
                  <Box xs={3} className="first-block">
                    <img src={visualAid} />
                  </Box>
                  <Box xs={7} ml={4}>
                    <p>
                      SPRIX<sup>®</sup> Women's Health Professional Brochure
                    </p>

                    <a
                      href={'/pdfs/Sprix_CVA_Womens_Health.pdf'}
                      target={'_blank'}
                      onClick={trackingDentalProffesional}
                    >
                      <Button backgroundColor={'#f7db4b'} color={'#19386e'}>
                        Download the Women's Health Professional Brochure
                      </Button>
                    </a>
                  </Box>
                </Flex>
              </div>
            </ContentBlock>
          </div>
        </div>
        <div className="empty-space-1rem"></div>
        <div className="blue">
          <div className="resource-container">
            <ContentBlock>
              <div className="box-container">
                <Flex>
                  <Box xs={3} className="first-block">
                    <img className="pat-brochure" src={patBro} />
                  </Box>
                  <Box col={7} ml={4}>
                    <p>
                      SPRIX<sup>®</sup> Patient Brochure
                    </p>
                    <a
                      href={'/pdfs/SPRIX_Patient_Brochure.pdf'}
                      target={'_blank'}
                      onClick={trackingPrescription}
                    >
                      <Button backgroundColor={'#f7db4b'} color={'#19386e'}>
                        Download the Patient Brochure
                      </Button>
                    </a>
                  </Box>
                </Flex>
              </div>
            </ContentBlock>
          </div>
        </div>
        <div className="empty-space-1rem"></div>
        <div className="lt-blue">
          <div className="resource-container">
            <ContentBlock>
              <div className="box-container">
                <Flex>
                  <Box xs={3} className="first-block">
                    <img src={spanishBrochure} />
                  </Box>
                  <Box col={7} ml={4}>
                    <p>
                      SPRIX<sup>®</sup> Patient Brochure – Spanish
                    </p>
                    <a
                      href={'/pdfs/SPRIX_Patient_Brochure_Spanish.pdf'}
                      target={'_blank'}
                      onClick={trackingSpanishCoreAid}
                    >
                      <Button backgroundColor={'#f7db4b'} color={'#19386e'}>
                        Download Patient Brochure – Spanish
                      </Button>
                    </a>
                  </Box>
                </Flex>
              </div>
            </ContentBlock>
          </div>
        </div>
        <div className="empty-space-1rem"></div>
        <div className="blue">
          <div className="resource-container">
            <ContentBlock>
              <div className="box-container">
                <Flex>
                  <Box xs={3} className="first-block">
                    <img src={pi} />
                  </Box>
                  <Box xs={7} ml={4}>
                    <p className="small-pad">Full Prescribing Information </p>
                    <p className="smaller">
                      Review the full Prescribing Information for SPRIX
                      <sup>®</sup>
                    </p>
                    <a
                      href={'/pdfs/SPRIX_FPI_Jan 2018.pdf'}
                      target={'_blank'}
                      onClick={trackingHCPPI}
                    >
                      <Button backgroundColor={'#f7db4b'} color={'#19386e'}>
                        Download the Prescribing Information
                      </Button>
                    </a>
                  </Box>
                </Flex>
              </div>
            </ContentBlock>
          </div>
        </div>
      </CommonContainer>
    </Layout>
  );
};

export default Resources;
